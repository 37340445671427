import React, { Component } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/functions";
import worker from "workerize-loader!./worker"; // eslint-disable-line import/no-webpack-loader-syntax

import Bytedex from "./bytedex";

Bytedex.init();

const Context = React.createContext();

var marketDataLoadingRequests = {};

const workerInstance2 = worker();

class ContextProvider extends Component {
  state = {
    screenWidth: window.innerWidth,
    version: 1,
    isLoadingApp: true,
    user: null,
    name: "",
    email: "",
    maxBot: {
      nash: 0,
      binance: 0,
      paper_binance: 0,
      bytedex: 0,
      binance_margin: 0,
    },
    exchangesList: [
      "nash",
      "binance",
      "paper_binance",
      "binance_margin",
      "bytedex",
    ],
    exchangeSettings: {
      customBots: {
        nash: false,
        bytedex: false,
        ascendex: false,
        gateio: ["CUSTOM-01-SELL"],
        mexc: ["CUSTOM-01-SELL", "CUSTOM-03-VOLUME"],
        okx: ["CUSTOM-02-COPY"],
        kucoin: ["CUSTOM-01-SELL"],
        bitget: ["CUSTOM-01-SELL"],
        bitmart: ["CUSTOM-03-VOLUME"],
        whitebit: ["CUSTOM-03-VOLUME"],
        toobit: ["CUSTOM-03-VOLUME"],
      },
      mmbots: {
        nash: ["MARKET-MAKER", "SPREAD"],
        bytedex: ["MARKET-MAKER", "SPREAD"],
        ascendex: ["MARKET-MAKER", "SPREAD", "MARKET-MAKER-GRID", "ARBITRAGE"],
        gateio: ["MARKET-MAKER", "SPREAD", "MARKET-MAKER-GRID", "ARBITRAGE"],
        kucoin: ["MARKET-MAKER", "SPREAD", "MARKET-MAKER-GRID", "ARBITRAGE"],
      },
      gridBots: {
        nash: true,
        bytedex: true,
        binance: true,
        paper_binance: true,
        binance_margin: true,
      },
      dcaBots: {
        nash: true,
        bytedex: true,
        binance: true,
        paper_binance: true,
        binance_margin: true,
      },
      trendBots: {
        nash: true,
        bytedex: true,
        binance: true,
        paper_binance: true,
        binance_margin: true,
      },
      swingBots: {
        nash: true,
        bytedex: true,
        binance: true,
        paper_binance: true,
        binance_margin: true,
      },
    },
    exchanges: [],
    exchangesById: {},
    selectedExchange: null,
    portfolioRequest: {},
    accountBalances: {},
    isLoggedIn: false,
    uid: null,
    authError: "",
    isRestricted: false,
    alert: { isAvailable: false, msg: "", type: "warning" },
    topNotification: {
      isAvailable: false,
      msg: "Successfully Logged In",
      type: "alert-success",
    },
    emailVerificationSentAt: 0,
    telegram_notifications: {
      filled: true,
      pending: true,
      cancelled: true,
      insufficientFunds: true,
      dealComplete: true,
    },
    news: [],
    topPerformers: [],
    backTestingDataSwing: {
      BTCUSDT: null,
      ETHUSDT: null,
      NEOUSDT: null,
      LINKUSDT: null,
      ETHBTC: null,
    },
    isAllTopperformersReceived: false,
    customNotification: false,
    isNewUser: false,
    coinBalance: 0,
    coinTransfers: [],
    referralsList: [],
    shop: {},
    subcription: "FREE",
    purchasedItems: {},
    referralsCount: 0,
    activeReferralsCount: 0,
    referralID: "",
    marketDataAll: {},
    marketDataAllList: {},
    tradingCompetitionTotalRewards: 50,
    tradingCompetitionLeaderBoard: {},
    quoteAssetsPrices: {
      eur: 1,
      try: 1,
      bext: 1,
      usdt: 1,
      dai: 1,
      usdc: 1,
      btc: 1,
      eth: 1,
      tusd: 1,
      "usdc.p": 1,
    },
    isCustomUser: false,
    customData: {
      availableExchanges: [],
      availableBotTypes: [],
    },
  };

  componentDidMount() {
    this.authenticateUser();
    this.getTopPerformers();
    this.getTradingCompetitionTotalRewards();
    this.getTradingCompetitionLeaderBoard();
    this.getShopData();

    this.getQuoteAssetsPrices();

    this.loadMarketData("nash");
    setTimeout(() => {
      this.loadMarketData("mexc");
    }, 1000);
    setTimeout(() => {
      this.loadMarketData("gateio");
    }, 1500);
    setTimeout(() => {
      this.loadMarketData("bitget");
    }, 2000);
    setTimeout(() => {
      this.loadMarketData("kucoin");
    }, 2500);
    setTimeout(() => {
      this.loadMarketData("binance");
    }, 3000);
    setTimeout(() => {
      this.loadMarketData("bitmart");
    }, 3500);
    setTimeout(() => {
      this.loadMarketData("okx");
    }, 4000);
    setTimeout(() => {
      this.loadMarketData("whitebit");
    }, 4500);
    setTimeout(() => {
      this.loadMarketData("toobit");
    }, 5000);
    setTimeout(() => {
      this.loadMarketData("woo");
    }, 6000);
    window.addEventListener("resize", this.updateDimensions);

    workerInstance2.addEventListener("message", (message) => {
      if (message.data && message.data.type === "getMarketData") {
        // props.setVolumeNprofit(message.data.data);

        var marketData = this.state.marketDataAll;
        marketData[message.data.exchange] = message.data.marketData;

        var marketDataList = this.state.marketDataAllList;
        marketDataList[message.data.exchange] = message.data.lisst;

        if (message.data.exchange === "binance") {
          marketData["paper_binance"] = message.data.marketData;
          marketDataList["paper_binance"] = message.data.lisst;
          marketData["binance_margin"] = message.data.marketData;
          marketDataList["binance_margin"] = message.data.lisst;
        }

        this.setState({
          marketDataAll: marketData,
          marketDataAllList: marketDataList,
        });
        marketDataLoadingRequests[message.data.exchange] = false;
      } else if (message.data && message.data.type === "getFilledOrders") {
        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURI(message.data.csvStr);
        hiddenElement.target = "_blank";
        hiddenElement.download = message.data.name + "_filled_orders.csv";
        hiddenElement.click();
      }
    });
  }

  generateFilledOrdersCSV = async (props) => {
    if (props.id && props.id.length > 0) {
      firebase
        .database()
        .ref(`/filledOrders/${this.state.uid}/${props.id}`)
        .orderByChild("filledAt")
        .limitToLast(10000)
        .once("value")
        .then((snapshot) => {
          console.log("data received", snapshot.numChildren());
          workerInstance2.getFilledOrders(props, snapshot.val());
        });
    }
  };

  getQuoteAssetsPrices = async () => {
    var tickers = await Bytedex.getAllTickers();

    if (tickers) {
      var priceList = { usdt: 1, usdc: 1, "usdc.p": 1 };

      if (tickers["BTC_USDT"]) {
        priceList.btc = tickers["BTC_USDT"].last_price;
      }
      if (tickers["ETH_USDT"]) {
        priceList.eth = tickers["ETH_USDT"].last_price;
      }
      if (tickers["USDT_TRY"]) {
        priceList.try = 1 / tickers["USDT_TRY"].last_price;
      }
      if (tickers["USDT_DAI"]) {
        priceList.dai = 1 / tickers["USDT_DAI"].last_price;
      }
      if (tickers["BEXT_USDT"]) {
        priceList.bext = tickers["BEXT_USDT"].last_price;
      }
      if (tickers["EUR_USDT"]) {
        priceList.eur = tickers["EUR_USDT"].last_price;
      }

      this.setState({ quoteAssetsPrices: priceList });
    }
  };

  getMarketData = (bot) => {
    if (!this.state.marketDataAll || !this.state.marketDataAll[bot.exchange]) {
      this.loadMarketData(bot.exchange);
    }
    return this.state.marketDataAll[bot.exchange]
      ? this.state.marketDataAll[bot.exchange][bot.market]
      : null;
  };

  sleep = (ms) => {
    // console.log(
    //   "inside sleeping > ",
    //   ms,
    //   " >> ",
    //   new Date().toLocaleString("en-US", { timeZone: "Asia/Colombo" })
    // );
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };

  getTradingCompetitionTotalRewards = () => {
    firebase
      .database()
      .ref("/tradingCpmpetitionTotalRewards")
      .once("value", (snapshot) => {
        this.setState({
          tradingCompetitionTotalRewards: snapshot.val(),
        });
      });
  };
  getTradingCompetitionLeaderBoard = () => {
    firebase
      .database()
      .ref("/tradingCompetition/01")
      .on("value", (snapshot) => {
        this.setState({
          tradingCompetitionLeaderBoard: snapshot.val(),
        });
      });
  };

  getTopPerformers = () => {
    firebase
      .database()
      .ref("/topPerformers")
      .orderByChild("roi")
      .limitToLast(30)
      .on("value", (snapshot) => {
        var list = [];
        for (const key in snapshot.val()) {
          const element = snapshot.val()[key];
          list.push(element);
        }
        this.setState({
          topPerformers: list.sort(this.compareROI),
          isAllTopperformersReceived: false,
        });
      });
  };
  getShopData = async () => {
    var data = await firebase.database().ref("/shop").once("value");

    this.setState({
      shop: data.val(),
    });
  };
  getCoinBalance = (uid) => {
    firebase
      .database()
      .ref("/coins/" + uid)
      .on("value", (snapshot) => {
        var list = [];
        var balance = 0;
        for (const key in snapshot.val()) {
          const element = snapshot.val()[key];
          list.push(element);
          balance += parseFloat(element.amount);
        }
        list.sort(this.compareDateReverse);
        this.setState({
          coinTransfers: list,
          coinBalance: balance,
        });
      });
  };
  getPurchasedItems = (uid) => {
    firebase
      .database()
      .ref("/purchasedItems")
      .orderByChild("uid")
      .equalTo(uid)
      .on("value", (snapshot) => {
        var list = {};

        for (const key in snapshot.val()) {
          const element = snapshot.val()[key];
          var daysLeft = Math.ceil(
            (new Date(element.expireAt) - new Date()) / 86400000
          );
          list[element.itemId] = { ...element, daysLeft: daysLeft, id: key };
        }

        this.setState({
          purchasedItems: list,
        });
      });
  };
  getReferralUsers = (uid) => {
    firebase
      .database()
      .ref("/referrals/" + uid)
      .on("value", (snapshot) => {
        var list = [];
        var total = 0;
        var active = 0;
        for (const key in snapshot.val()) {
          const element = snapshot.val()[key];
          list.push(element);
          total++;
          if (element.active) {
            active++;
          }
        }

        list.sort(this.compareDateReverse);
        this.setState({
          referralsList: list,
          referralsCount: total,
          activeReferralsCount: active,
        });
      });
  };
  getBackTestingSwing = (symbol) => {
    firebase
      .database()
      .ref("swingTraderBackTest/" + symbol)
      .orderByChild("closeTime")
      .once("value", (snapshot) => {
        if (snapshot.val()) {
          var data = this.state.backTestingDataSwing;
          data[symbol] = snapshot.val();
          this.setState({
            backTestingDataSwing: data,
          });
        }
      });
  };
  compareROI = (a, b) => {
    if (a.roi < b.roi) {
      return 1;
    }
    if (a.roi > b.roi) {
      return -1;
    }
    return 0;
  };
  compareDate = (a, b) => {
    if (a.createdAt > b.createdAt) {
      return 1;
    }
    if (a.createdAt < b.createdAt) {
      return -1;
    }
    return 0;
  };
  compareDateReverse = (a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    return 0;
  };
  loadMarketData = async (exchange) => {
    if (
      exchange &&
      (!this.state.marketDataAll || !this.state.marketDataAll[exchange]) &&
      exchange !== "paper_binance" &&
      exchange !== "binance_margin" &&
      !marketDataLoadingRequests[exchange]
    ) {
      marketDataLoadingRequests[exchange] = true;

      firebase
        .database()
        .ref(`/marketData/${exchange}`)
        .once("value")
        .then((snapshot) => {
          workerInstance2.getMarketData(exchange, snapshot.val());
        });
    }
  };
  getNewsData = async () => {
    var data = await firebase.database().ref("/news").once("value");
    var lisst = [];
    for (const key in data.val()) {
      const element = data.val()[key];
      if (element) {
        lisst.push(element);
      }
    }
    lisst.sort(this.compareDateReverse);

    this.setState({
      news: lisst,
    });
  };
  getAccountBalancesData = async (uid) => {
    var data = await firebase
      .database()
      .ref("/accountBalances/" + uid)
      .once("value");
    var accountBalances = {};
    for (let excha in data.val()) {
      accountBalances[excha] = {};

      var tempList = [];
      //set week fields
      for (var dayB in data.val()[excha]) {
        tempList.push(data.val()[excha][dayB]);
      }
      var daata = tempList.sort(this.compareDate);

      accountBalances[excha]["week"] = {};
      accountBalances[excha]["today"] = {};
      accountBalances[excha]["month"] = {};

      daata.forEach((element) => {
        var dateObj2 = new Date(
          new Date(element.createdAt).setUTCHours(0, 0, 0, 0)
        );
        var month2 = dateObj2.getUTCMonth() + 1; //month2s from 1-12
        var day2 = dateObj2.getUTCDate();

        var newdate2 = month2 + "/" + day2;

        //calculate week balance
        if (
          new Date(element.createdAt) -
            new Date(new Date().setUTCHours(-6 * 24, 0, 0, 0)) >
          0
        ) {
          if (element.nashChannelsBalance && element.nashChannelsBalance > 0) {
            accountBalances[excha]["week"][newdate2] =
              element.nashChannelsBalance;
          } else if (element.balance && element.balance > 0) {
            accountBalances[excha]["week"][newdate2] = element.balance;
          }
        }

        //calculate today balance
        if (
          new Date(element.createdAt) -
            new Date(new Date().setUTCHours(-24, 0, 0, 0)) >
            0 &&
          new Date(new Date().setUTCHours(0, 0, 0, 0)) -
            new Date(element.createdAt) >
            0
        ) {
          if (element.nashChannelsBalance && element.nashChannelsBalance > 0) {
            accountBalances[excha]["today"][newdate2] =
              element.nashChannelsBalance;
          } else if (element.balance && element.balance > 0) {
            accountBalances[excha]["today"][newdate2] = element.balance;
          }
        }

        //calculate month balance
        if (
          new Date(element.createdAt) -
            new Date(new Date().setUTCHours(-30 * 24, 0, 0, 0)) >
          0
        ) {
          if (element.nashChannelsBalance && element.nashChannelsBalance > 0) {
            accountBalances[excha]["month"][newdate2] =
              element.nashChannelsBalance;
          } else if (element.balance && element.balance > 0) {
            accountBalances[excha]["month"][newdate2] = element.balance;
          }
        }
      });
    }
    this.setState({ accountBalances: accountBalances });
  };
  updateDimensions = () => {
    this.setState({ screenWidth: window.innerWidth });
  };
  authenticateUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          user: user,
          email: user.email,
          uid: user.uid,
        });

        this.updateUserData(user);

        this.getNewsData();
        console.log("login sucess");
      } else {
        this.setState({
          coinBalance: 0,
          coinTransfers: [],
          referralsList: [],
          subcription: "FREE",
          purchasedItems: {},
          referralsCount: 0,
          activeReferralsCount: 0,
          referralID: "",
          isLoggedIn: false,
          user: null,
          uid: null,
          isLoadingApp: false,
          email: "",
          authError: "",
          name: "",
          telegram_id: "",
          exchanges: [],
          exchangesById: {},
          accountBalances: {},
          topNotification: {
            isAvailable: false,
            msg: "Successfully Logged In",
            type: "alert-success",
          },
          emailVerificationSentAt: 0,
          telegram_notifications: {
            filled: true,
            pending: true,
            cancelled: true,
            insufficientFunds: true,
            dealComplete: true,
          },
          maxBot: {
            nash: 0,
            binance: 0,
            paper_binance: 0,
            bytedex: 0,
            binance_margin: 0,
          },
          isCustomUser: false,
        });
      }
    });
  };
  sendPortfolioRequest = (element, waitTime) => {
    if (
      element &&
      (!this.state.portfolioRequest[element.id] ||
        new Date() - this.state.portfolioRequest[element.id] > waitTime)
    ) {
      firebase
        .database()
        .ref("/portfolioRequest")
        .push({
          ...element,
          userID: this.state.uid,
        });
      var upData = this.state.portfolioRequest;
      // console.log(upData);
      upData[element.id] = Date.now();
      this.setState({
        portfolioRequest: upData,
      });
    }
  };
  setSelectedExchange = (element) => {
    if (element) {
      this.sendPortfolioRequest(element, 30000);
      try {
        localStorage.setItem("selectedExchange", element.id);
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({
      selectedExchange: element,
    });
  };
  updateUserData = async (user) => {
    firebase
      .database()
      .ref("/users/" + user.uid)
      .on(
        "value",
        (snapshot) => {
          if (snapshot.val()) {
            var exchanges = [];

            var selectedExchange = false;
            var selecExOb = {};

            for (var id in snapshot.val().exchanges) {
              var ele = snapshot.val().exchanges[id];
              if (ele) {
                exchanges.push({ ...ele, id: id });
                selecExOb[id] = { ...ele, id: id };

                if (
                  this.state.selectedExchange &&
                  this.state.selectedExchange.id === id
                ) {
                  selectedExchange = true;
                }
              }
            }

            if (exchanges.length === 0) {
              this.setSelectedExchange(null);
            } else if (!selectedExchange && exchanges) {
              var selecex = null;

              try {
                selecex = localStorage.getItem("selectedExchange");
              } catch (error) {
                console.log(error);
              }
              var acc = exchanges[0];
              if (selecex && selecex.length > 0 && exchanges.length > 1) {
                exchanges.forEach((element) => {
                  if (element.id === selecex) {
                    acc = element;
                  }
                });
              }

              this.setSelectedExchange(acc);
            }

            this.setState({
              subcription: snapshot.val().subcription
                ? snapshot.val().subcription
                : "FREE",
              referralID: snapshot.val().referralID,
              isNewUser: false,
              isLoggedIn: true,
              isLoadingApp: false,
              authError: "",
              name: snapshot.val().name,
              exchanges: exchanges,
              exchangesById: selecExOb,
              maxBot: {
                nash: snapshot.val().maxNashBot
                  ? snapshot.val().maxNashBot
                  : snapshot.val().maxBot,
                binance: snapshot.val().maxBinanceBot
                  ? snapshot.val().maxBinanceBot
                  : 5,
                binance_margin: snapshot.val().maxBinanceBot
                  ? snapshot.val().maxBinanceBot
                  : 5,
                paper_binance: snapshot.val().maxPaperBot
                  ? snapshot.val().maxPaperBot
                  : 3,
                bytedex: snapshot.val().maxBytedexBot
                  ? snapshot.val().maxBytedexBot
                  : 10,
              },
              isRestricted: snapshot.val().isRestricted,
              customNotification: snapshot.val().customNotification
                ? true
                : false,
              telegram_id: snapshot.val().telegram_id,
              telegram_notifications: snapshot.val().telegram_notifications
                ? snapshot.val().telegram_notifications
                : this.state.telegram_notifications,
              isCustomUser: snapshot.val().isCustomUser
                ? snapshot.val().isCustomUser
                : false,
              customData: {
                availableExchanges:
                  snapshot.val().isCustomUser &&
                  snapshot.val().customData &&
                  snapshot.val().customData.availableExchanges &&
                  snapshot.val().customData.availableExchanges.length > 0
                    ? snapshot.val().customData.availableExchanges.split(",")
                    : [],
                availableBotTypes:
                  snapshot.val().isCustomUser &&
                  snapshot.val().customData &&
                  snapshot.val().customData.availableBotTypes &&
                  snapshot.val().customData.availableBotTypes.length > 0
                    ? snapshot.val().customData.availableBotTypes.split(",")
                    : [],
              },
            });
            this.getAccountBalancesData(user.uid);
            this.getCoinBalance(user.uid);
            this.getReferralUsers(user.uid);
            this.getPurchasedItems(user.uid);

            if (snapshot.val().isRestricted) {
              this.setState({
                alert: {
                  isAvailable: true,
                  msg: "You are not allow to create new bots.",
                  type: "warning",
                },
              });
            }
          } else if (!this.state.isNewUser) {
            this.setState({
              isLoggedIn: false,
              user: null,
              uid: null,
              isLoadingApp: false,
              name: "",
              email: "",
              authError: "Auth error. Please Try again  or contact support.",
              telegram_id: "",
              exchanges: [],
              exchangesById: {},
              coinBalance: 0,
              coinTransfers: [],
              referralsList: [],
              subcription: "FREE",
              purchasedItems: {},
              referralsCount: 0,
              activeReferralsCount: 0,
              referralID: "",
              isCustomUser: false,
            });
          }

          // console.log(this.state.isNewUser);
        },
        (error) => {
          console.log(error);

          this.setState({
            isLoggedIn: false,
            isLoadingApp: false,
            name: "",
            email: "",
            user: null,
            uid: null,
            authError: "",
            isCustomUser: false,
          });
        }
      );
  };
  signOut = () => {
    // console.log("so");
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("user SignOut sucess");
      })
      .catch(function (error) {
        console.log(error);
      });
    this.setState({
      name: "",
      email: "",
      user: null,
      uid: null,
      isLoggedIn: false,
      coinBalance: 0,
      coinTransfers: [],
      referralsList: [],
      subcription: "FREE",
      purchasedItems: {},
      referralsCount: 0,
      activeReferralsCount: 0,
      referralID: "",
      isCustomUser: false,
    });
  };
  googleSignIn = async (code) => {
    var provider = new firebase.auth.GoogleAuthProvider();
    this.setState({
      isLoadingApp: true,
    });

    try {
      var result = await firebase.auth().signInWithPopup(provider);

      // console.log(result);
      if (result.additionalUserInfo.isNewUser) {
        this.setState({
          isNewUser: true,
        });
        var data = {
          code: code,
          email: result.user.email,
        };

        var result2 = await firebase.functions().httpsCallable("handleNewUser")(
          data
        );

        var data2 = result2.data;

        console.log("Status: ", data2.status);
      }
    } catch (error) {
      console.log(error);

      this.setState({
        authError: "Auth error. Please Try again or contact support.",
        isLoadingApp: false,
      });
    }
  };
  sendEmailVerification = () => {
    if (this.state.user) {
      if (new Date() - this.state.emailVerificationSentAt > 300000) {
        this.state.user
          .sendEmailVerification()
          .then(() => {
            // Email sent.
            console.log("verification email sent");
            this.setState({
              emailVerificationSentAt: Date.now(),
              topNotification: {
                isAvailable: true,
                msg: "Verification email sent.",
                type: "alert-success",
              },
            });
          })
          .catch((error) => {
            // An error happened.
            this.setState({
              topNotification: {
                isAvailable: true,
                msg: "Verification email send failed.",
                type: "alert-danger",
              },
            });
            console.log(error);
          });
      } else {
        this.setState({
          topNotification: {
            isAvailable: true,
            msg: "Please wait 5min.",
            type: "alert-warning",
          },
        });
      }
    }
  };
  emailSignUp = async (email, password, code) => {
    this.setState({
      isLoadingApp: true,
    });

    try {
      var userCredential = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      this.setState({
        isNewUser: true,
      });

      // Signed in

      var data = {
        code: code,
        email: userCredential.user.email,
      };

      var result = await firebase.functions().httpsCallable("handleNewUser")(
        data
      );

      var data = result.data;

      console.log("Status: ", data.status);

      userCredential.user
        .sendEmailVerification()
        .then(function () {
          // Email sent.
          console.log("verification email sent");
        })
        .catch(function (error) {
          // An error happened.
          console.log(error);
        });
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(errorCode, errorMessage);
      this.setState({
        authError: errorMessage,
        isLoadingApp: false,
      });
    }
  };
  emailSignIn = (email, password) => {
    this.setState({
      isLoadingApp: true,
    });
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        console.log("Signed in Success.");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
        this.setState({
          isLoadingApp: false,
        });
        if (errorCode === "auth/user-not-found") {
          this.setState({
            authError: "There is no user record corresponding to this email.",
          });
        } else if (errorCode === "auth/wrong-password") {
          this.setState({
            authError: "The password is invalid",
          });
        } else {
          this.setState({
            authError: errorMessage,
          });
        }
      });
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          googleSignIn: this.googleSignIn,
          emailSignIn: this.emailSignIn,
          emailSignUp: this.emailSignUp,
          setSelectedExchange: (val) => this.setSelectedExchange(val),
          generateFilledOrdersCSV: (val) => this.generateFilledOrdersCSV(val),
          sleep: (val) => this.sleep(val),
          getMarketData: (val) => this.getMarketData(val),
          loadMarketData: (val) => this.loadMarketData(val),
          setTopPerformers: (val) => this.setState({ topPerformers: val }),
          setisAllTopperformersReceived: (val) =>
            this.setState({ isAllTopperformersReceived: val }),
          sendPortfolioRequest: (val1, val2) =>
            this.sendPortfolioRequest(val1, val2),
          getBackTestingSwing: (val1) => this.getBackTestingSwing(val1),
          setTopNotification: (isAvailable, msg, type) =>
            this.setState({
              topNotification: {
                isAvailable: isAvailable,
                msg: msg,
                type: type,
              },
            }),
          signOut: this.signOut,
          sendEmailVerification: this.sendEmailVerification,
          setAlert: (val, msg, type) =>
            this.setState({
              alert: {
                isAvailable: val,
                msg: msg,
                type: type,
              },
            }),
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export { ContextProvider, Context };
