import { SET_FILLED_ORDERS } from "../actions/types";

const initialState = {
  data: [],
};

const filledOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILLED_ORDERS:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default filledOrdersReducer;
