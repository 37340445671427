import { SET_PORTFOLIO } from "../actions/types";

const initialState = {
  portfolioData: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PORTFOLIO:
      return {
        ...state,
        portfolioData: action.payload.portfolioData,
      };

    default:
      return state;
  }
};

export default reducer;
