// import reportWebVitals from "./reportWebVitals";

import React from "react";
import ReactDOM from "react-dom"; // { hydrate, render }
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { ContextProvider } from "./backend/context";

import { createStore, applyMiddleware, compose } from "redux";
import allReducer from "./reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

const initialState = {};
const middleware = [thunk];

const store = createStore(
  allReducer,
  initialState,
  compose(
    applyMiddleware(...middleware)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextProvider>
        <Router>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/CoinnerOfficial"
          >
            <img
              src="/img/telegram.png"
              alt="telegram coinner"
              id="fixedbutton"
            />
          </a>
          <App />
        </Router>
      </ContextProvider>
    </Provider>
    ,
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// reportWebVitals(console.log);
