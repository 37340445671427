import { combineReducers } from "redux";

import notificationsReducer from "./notifications";
import filledOrdersReducer from "./filledOrders";
import pendingOrdersReducer from "./pendingOrders";
import cancelledOrdersReducer from "./cancelledOrders";
import volumeNprofitReducer from "./volumeNprofit";
import portfolioReducer from "./portfolio";
import pendingBotsReducer from "./pendingBots";
import botsReducer from "./bots";

const allReducers = combineReducers({
  notifications: notificationsReducer,
  filledOrders: filledOrdersReducer,
  pendingOrders: pendingOrdersReducer,
  cancelledOrders: cancelledOrdersReducer,
  volumeNprofit: volumeNprofitReducer,
  portfolio: portfolioReducer,
  pendingBots: pendingBotsReducer,
  bots: botsReducer,
});

export default allReducers;
