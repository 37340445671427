export const firebaseConfig = {
  apiKey: "",
  authDomain: "auth.coinner.co",
  databaseURL: "",
  projectId: "coinnerbots",
  storageBucket: "coinnerbots.appspot.com",
  messagingSenderId: "855414349714",
  appId: "1:855414349714:web:7e1f3cfeae7954cd3feb4b",
  measurementId: "G-EREN6BQ026",
};
