export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_PORTFOLIO = "SET_PORTFOLIO";
export const SET_PENDING_BOTS = "SET_PENDING_BOTS";
export const SET_BOTS = "SET_BOTS";
export const SET_MAX_BOTS = "SET_MAX_BOTS";

export const SET_FILLED_ORDERS = "SET_FILLED_ORDERS";
export const SET_PENDING_ORDERS = "SET_PENDING_ORDERS";
export const SET_CANCELLED_ORDERS = "SET_CANCELLED_ORDERS";
export const SET_VOLUME_PROFIT = "SET_VOLUME_PROFIT";
