import React from "react";
import { deviceDetect } from "react-device-detect";
import firebase from "firebase/compat/app";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to trigger fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log error or send logging data to log management tool
    console.log("handled error", error);

    firebase
      .database()
      .ref("/errorLog")
      .push({
        uid:
          firebase.auth().currentUser && firebase.auth().currentUser.uid
            ? firebase.auth().currentUser.uid
            : "",
        error: { message: error.message, stack: error.stack },
        connection: navigator.connection.effectiveType,
        device: deviceDetect(),
        createdAt: Date.now(),
      });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
