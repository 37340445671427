import { SET_NOTIFICATIONS } from "../actions/types";

const initialState = {
  notifications: [],
  newNotifications: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.notifications,
        newNotifications: action.payload.newNotifications,
      };

    default:
      return state;
  }
};

export default reducer;
