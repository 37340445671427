import React from "react";

import { LoopCircleLoading } from "react-loadingg";

function LoadingScreen(props) {
  return (
    <div
      className="center h-100 w-100"
      style={
        props.style
          ? {
              position: "fixed",
              zIndex: 100000,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              ...props.style,
            }
          : {
              position: "fixed",
              zIndex: 100000,
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }
      }
    >
      <LoopCircleLoading color="#0080ff" />
    </div>
  );
}

export default LoadingScreen;
