import { SET_CANCELLED_ORDERS } from "../actions/types";

const initialState = {
  data: [],
};
const cancelledOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANCELLED_ORDERS:
      return {
        ...state,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default cancelledOrdersReducer;
