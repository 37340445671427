import React, { Suspense, lazy, useEffect } from "react";
import { deviceDetect } from "react-device-detect";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import Chart from "chart.js";

import { firebaseConfig } from "./backend/firebaseConfig";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getToken,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";

import CookieConsent from "react-cookie-consent";
import { Context } from "./backend/context";
import LoadingScreen from "./components/loadingScreen";

import ErrorBoundary from "./errorBoundary";

import axios from "axios";

const Home = lazy(() => import("./components/Home/home"));
const Platform = lazy(() => import("./components/Platform/platform"));

if (!firebase.apps.length) {
  var fireApp = firebase.initializeApp({
    ...firebaseConfig,
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  });

  const appCheck = initializeAppCheck(fireApp, {
    provider: new ReCaptchaEnterpriseProvider(
      process.env.REACT_APP_RECAPTCHA_KEY
    ),
    isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
  });

  getToken(appCheck)
    .then((res) => {
      console.log("token >>>>");
    })
    .catch(async (err) => {
      console.log("appcheck error 2 ", err);
      console.log("start");

      try {
        var ipData = "";

        try {
          ipData = await axios.get("https://geolocation-db.com/json/");
        } catch (error) {
          // console.log(error);
        }

        var data = {
          uid:
            firebase.auth().currentUser && firebase.auth().currentUser.uid
              ? firebase.auth().currentUser.uid
              : "",
          error: { message: err.message, stack: err.stack },
          connection:
            navigator &&
            navigator.connection &&
            navigator.connection.effectiveType
              ? navigator.connection.effectiveType
              : "",
          device: deviceDetect(),
          ip: ipData && ipData.data && ipData.data.IPv4 ? ipData.data.IPv4 : "",
          country:
            ipData && ipData.data && ipData.data.country_name
              ? ipData.data.country_name
              : "",
          createdAt: Date.now(),
          isAppCheckError: true,
          pathname: document.location.pathname,
        };
        // console.log(data);

        firebase
          .database()
          .ref("/errorLog")
          .push(data)
          .then((res) => {
            // console.log("res", res);
          })
          .catch((err) => {
            // console.log("err", err);
          });
      } catch (error) {
        console.log(error);
      }
      console.log("done");
    });

  firebase.analytics();
}

firebase
  .database()
  .ref("/version")
  .on("value", (snapshot) => {
    console.log("version :", snapshot.val());

    try {
      if (!localStorage.getItem("version")) {
        localStorage.setItem("version", snapshot.val());
      } else if (snapshot.val() !== localStorage.getItem("version")) {
        localStorage.setItem("version", snapshot.val());
        if (document.location.hostname !== "localhost") {
          indexedDB.deleteDatabase("firebase-app-check-database");
        }
        alert("New version available!");
        document.location.reload(true);
      }
    } catch (error) {
      console.log(error);
    }
  });

function App() {
  const { user, uid, isLoggedIn, isLoadingApp } = React.useContext(Context);
  const history = useHistory();

  const { pathname } = useLocation();

  useEffect(() => {
    if (uid && user && isLoggedIn) {
      if (pathname === "/") {
        history.push("/account");
      }
      if (document.getElementById("closeAuthPopup")) {
        document.getElementById("closeAuthPopup").click();
      }
      let body = document.querySelector(".modal-open");
      if (body) {
        body.classList.remove("modal-open");
        body.removeAttribute("style");
        let divFromHell = document.querySelector(".modal-backdrop");
        body.removeChild(divFromHell);
      }
    } else if (
      pathname.split("/")[1] === "account" &&
      pathname.split("/")[2] === "bots" &&
      pathname.split("/")[3] === "copy"
    ) {
      history.push(
        `/${pathname.split("/")[2]}/${pathname.split("/")[3]}/${
          pathname.split("/")[4]
        }`
      );
    }
  }, [user, uid, isLoggedIn]);

  return (
    <ErrorBoundary>
      <div
        className="dark-blue-1"
        style={{
          height: "100%",
          position: "absolute",
          left: "0px",
          width: "100%",
        }}
      >
        <CookieConsent
          style={{
            backgroundColor: "#035eb9",
          }}
          containerClasses="text-center py-0"
          buttonStyle={{
            backgroundColor: "white",
            margin: "0 1rem 1rem 1rem",
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <button
          id="closeAuthPopup"
          data-dismiss="modal"
          data-backdrop="false"
          style={{ display: "none" }}
        ></button>
        <button
          data-toggle="modal"
          data-target="#authPopup"
          type="button"
          id="authpopupOpen"
          style={{ display: "none" }}
        ></button>
        {isLoadingApp && <LoadingScreen />}
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            <Route path="/account" component={Platform} />
            <Route path="/" component={Home} />
          </Switch>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
}

export default App;
