import { SET_BOTS, SET_MAX_BOTS } from "../actions/types";

const initialState = {
  isBotLimitHit: {},
  oldBots: [],
  customBots: [],
  swingBots: [],
  trendBots: [],
  gridBots: [],
  dcaBots: [],
  activeOldBots: [],
  activeCustomBots: [],
  activeGridBots: [],
  activeDcaBots: [],
  activeSwingBots: [],
  activeTrendBots: [],
  allBots: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BOTS:
      return {
        ...state,
        oldBots: action.payload.oldBots,
        customBots: action.payload.customBots,
        swingBots: action.payload.swingBots,
        trendBots: action.payload.trendBots,
        gridBots: action.payload.gridBots,
        dcaBots: action.payload.dcaBots,
        activeOldBots: action.payload.activeOldBots,
        activeCustomBots: action.payload.activeCustomBots,
        activeSwingBots: action.payload.activeSwingBots,
        activeTrendBots: action.payload.activeTrendBots,
        activeGridBots: action.payload.activeGridBots,
        activeDcaBots: action.payload.activeDcaBots,
        allBots: action.payload.allBots,
        isBotLimitHit: action.payload.isBotLimitHit,
      };
    case SET_MAX_BOTS:
      return {
        ...state,
        isBotLimitHit: action.payload.isBotLimitHit,
      };

    default:
      return state;
  }
};

export default reducer;
