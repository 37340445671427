import { SET_PENDING_BOTS } from "../actions/types";

const initialState = {
  oldBots: [],
  customBots: [],
  gridBots: [],
  dcaBots: [],
  swingBots: [],
  trendBots: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING_BOTS:
      return {
        ...state,
        oldBots: action.payload.oldBots,
        customBots: action.payload.customBots,
        swingBots: action.payload.swingBots,
        trendBots: action.payload.trendBots,
        gridBots: action.payload.gridBots,
        dcaBots: action.payload.dcaBots,
      };

    default:
      return state;
  }
};

export default reducer;
